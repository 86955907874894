
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import createCache, { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { ErrorProps } from 'next/error';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import DatabaseChecker from '@components/DatabaseChecker';
import DynamicBannerNotificationsManager from '@components/Dynamic/BannerNotifications';
import DynamicCookiesManager from '@components/Dynamic/CookiesManager';
import DynamicCssBaseline from '@components/Dynamic/CssBaseline';
import DynamicFooter from '@components/Dynamic/Footer';
import DynamicFreemiumDialog from '@components/Dynamic/FreemiumDialog';
import DynamicHead from '@components/Dynamic/Head';
import DynamicNavBar from '@components/Dynamic/NavBar';
import ErrorFallback from '@components/ErrorFallback';
import '@styles/global.css';
import TrackingWrapper from '@components/Tracking/TrackingWrapper';
import theme from '@styles/theme';
import { LOCAL_STORAGE_LANGUAGE } from '@utils/consts/language';
import { AuthProvider } from '@utils/context/auth';
import { BannerNotificationContextProvider } from '@utils/context/bannerNotifications';
import FreemiumDialogContextProvider from '@utils/context/freemiumDialog';
import SnackbarContextProvider from '@utils/context/snackbar';
import { UserContextProvider } from '@utils/context/user';
import useAmplitude from '@utils/hooks/useAmplitude';
import useMigrationIfNeeded from '@utils/hooks/useMigrationIfNeeded';
import usePageTracking from '@utils/hooks/usePageTracking';
import { BasicServerSideProps } from '@utils/serverSide/getBasicServerSideProps';
import { TrackingEventName } from '@utils/types/amplitude/events';
export interface LivvPublicAppProps extends AppProps<Partial<BasicServerSideProps>> {
    emotionCache?: EmotionCache;
    err?: ErrorProps;
}
const LivvPublicApp: NextPage<LivvPublicAppProps> = (props) => {
    const { Component, pageProps, err } = props;
    const { auth, clientOs, databaseAccessible, user, userPrivileges } = pageProps;
    const { asPath, events, locale, pathname, replace } = useRouter();
    const { logEvent } = useAmplitude();
    const { migrateIfNeeded } = useMigrationIfNeeded();
    const cache = createCache({
        key: 'css',
        prepend: true,
    });
    usePageTracking();
    useEffect(() => {
        const resetScrollPosition = () => {
            const flexContainer = document.getElementById('app-flex-container');
            if (flexContainer) {
                flexContainer.scrollTo(0, 0);
            }
        };
        events.on('routeChangeComplete', resetScrollPosition);
        return () => {
            events.off('routeChangeComplete', resetScrollPosition);
        };
    }, [events]);
    useEffect(() => {
        logEvent({
            name: TrackingEventName.PAGE_VIEW,
            // logged is always false for page not using our getBasicServerSideProps
            params: { logged_user: Boolean(user), screen_name: pathname },
        });
    }, [logEvent, pathname, user]);
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles && jssStyles.parentElement) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);
    useEffect(() => {
        migrateIfNeeded();
    }, [migrateIfNeeded]);
    useEffect(() => {
        if (window.localStorage.getItem(LOCAL_STORAGE_LANGUAGE)) {
            const localStorageLanguage = window.localStorage.getItem(LOCAL_STORAGE_LANGUAGE);
            if (localStorageLanguage !== locale) {
                replace(asPath, asPath, {
                    locale: window.localStorage.getItem(LOCAL_STORAGE_LANGUAGE) ?? undefined,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const hideEnrichmentElements = [
        '/password-recovery',
        '/search/tree-view',
        '/signin',
        '/signup',
    ].some((value) => asPath.startsWith(value));
    return (<ThemeProvider theme={theme}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {/* Be careful when changing this component, as it will have a major impact on the SSR */}
                <DatabaseChecker databaseAccessible={databaseAccessible}>
                    <AuthProvider initialAuth={auth}>
                        <UserContextProvider user={user} userPrivileges={userPrivileges}>
                            <FreemiumDialogContextProvider FreemiumDialog={DynamicFreemiumDialog}>
                                <BannerNotificationContextProvider>
                                    <CacheProvider value={cache}>
                                        <SnackbarContextProvider>
                                            <TrackingWrapper isLoggedUser={Boolean(user)}>
                                                <DynamicCssBaseline />
                                                <DynamicHead />
                                                <Box data-test-id="testing-root-flex-container" display="flex" flexDirection="column" height="100vh" id="app-flex-container" sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                                                    {!hideEnrichmentElements && (<DynamicBannerNotificationsManager />)}
                                                    <DynamicNavBar clientOs={clientOs ?? undefined}/>
                                                    <Box component="main" sx={{ flex: 1 }}>
                                                        <Component {...pageProps} err={err}/>
                                                    </Box>
                                                    <DynamicFooter />
                                                    {!user && <DynamicCookiesManager />}
                                                </Box>
                                            </TrackingWrapper>
                                        </SnackbarContextProvider>
                                    </CacheProvider>
                                </BannerNotificationContextProvider>
                            </FreemiumDialogContextProvider>
                        </UserContextProvider>
                    </AuthProvider>
                </DatabaseChecker>
            </ErrorBoundary>
        </ThemeProvider>);
};
const __Next_Translate__Page__191fa3c9618__ = LivvPublicApp;

    export default __appWithI18n(__Next_Translate__Page__191fa3c9618__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  